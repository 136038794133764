@import url(https://fonts.cdnfonts.com/css/vag-rounded-next);
.styles_scroll-to-top__2A70v {
  border-radius: 50% !important;
  background: linear-gradient(
    45deg,
    #7d5ff9 0%,
    #675fef 43%,
    #2876bd 92%
  ) !important;
  outline: none !important;
}

.styles_scroll-to-top__2A70v svg {
  fill: #fff !important;
  height: 22px;
}

.ReactModal__Overlay {
  z-index: 10000 !important;
}

.menu-active .millsberryClz-contact-menu{
  z-index: 8 !important;
}

body{font-family:"VAG Rounded Next", sans-serif;font-size:15px;line-height:24px;font-weight:400}.container-fluid{max-width:1140px}.dropdown-menu{padding:0;min-width:200px;margin:0;border-radius:0}.dropdown-item{font-size:15px;padding:5px 10px;outline:none}.async-block{position:relative;min-height:100px}.dropdown-toggle::after{content:"\f107";font:normal normal normal 14px/1 FontAwesome;border:none;vertical-align:baseline;vertical-align:initial}.with-overlay{position:relative}.with-overlay:after{content:"";position:absolute;top:0;left:0;width:100%;height:100%;z-index:0;background:linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.009) 11.7%, rgba(0,0,0,0.034) 22.1%, rgba(0,0,0,0.072) 31.2%, rgba(0,0,0,0.123) 39.4%, rgba(0,0,0,0.182) 46.6%, rgba(0,0,0,0.249) 53.1%, rgba(0,0,0,0.32) 58.9%, rgba(0,0,0,0.394) 64.3%, rgba(0,0,0,0.468) 69.3%, rgba(0,0,0,0.54) 74.1%, rgba(0,0,0,0.607) 78.8%, rgba(0,0,0,0.668) 83.6%, rgba(0,0,0,0.721) 88.7%, rgba(0,0,0,0.762) 94.1%, rgba(0,0,0,0.79) 100%)}.breadcrumb{margin-bottom:0}.btn{border-radius:0}#scrollUp{right:20px;bottom:20px;cursor:pointer;display:none;z-index:999;position:fixed;background:#f57979;border-color:#f57979}#scrollUp.visible{display:block}.single-block{padding-top:40px;padding-bottom:40px;position:relative}.single-block img{max-width:100% !important}.fixed-image{background:#eee;overflow:hidden;display:flex}.fixed-image img{width:100%;height:100%}.fixed-image.cover img{object-fit:cover}.fixed-image.contain img{object-fit:contain}.background-image{background-position:center center;background-repeat:no-repeat;background-color:#eee}.background-image.contain{background-size:contain}.background-image.cover{background-size:cover}iframe{max-width:100%}img.left,img.right{padding:0.25rem;background-color:#fff;border:1px solid #dee2e6;border-radius:0.25rem;max-width:100%;height:auto}img.left{margin:0 15px 15px 0;float:left}img.right{margin:0 0 15px 15px;float:right}

.modal img{
        max-width: 100% !important;
}

@media (min-width: 576px){
    .modal-dialog {
        max-width: 600px;
    }
}

.popup-description>*:last-child{
    margin-bottom: 0 !important;
}

.modal-footer{
    border-top: none;
}

.modal-close-btn {
    position: absolute;
    right: -35px;
    top: -9px;
    color: #fff;
    font-size: 35px;
    width: 35px;
    height: 35px;
    text-shadow: none;
    outline: none !important;
    opacity: 1;
}

.modal-close-btn:hover{
    color: #fff;
}

.popup-item+.popup-item{
    padding-top: 10px;
}

.modal-content{
    background-color: transparent;
    border: none;
}

.modal-body{
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
}

.modal-body+.modal-body{
    margin-top: 15px;
}

 @media(max-width:992px) {
    .modal-close-btn {
        right: 9px !important;
        top: 9px !important;
        z-index: 10 !important;
        line-height: 11px !important;
        background-color: #c00 !important;
        opacity: 1 !important;
    }

    .modal-close-btn span {
        display: inline-block;
        padding: 0 0 7px;
    }
}
.type-effect p {
    position: relative;
    text-transform: uppercase;
    font-size: 2em;
    font-weight: 900;
    letter-spacing: 4px;
    overflow: hidden;
    background: linear-gradient(90deg, #00b8de, #b02727, #00b8de);
    background-repeat: no-repeat;
    background-size: 80%;
    -webkit-animation: animate 3s linear infinite;
            animation: animate 3s linear infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@-webkit-keyframes animate {
    0% {
        background-position: -500%;
    }
    100% {
        background-position: 500%;
    }
}

@keyframes animate {
    0% {
        background-position: -500%;
    }
    100% {
        background-position: 500%;
    }
}
@-webkit-keyframes animate {
    0% {
        color: #f8de01;
        -webkit-transform: translateY(0);
                transform: translateY(0);
        margin-left: 0;
    }
    25% {
        color: #f8de01;
        -webkit-transform: translateY(-15px);
                transform: translateY(-15px);
        margin-left: 10px;
    }
    100% {
        color: #ff6e00;
        -webkit-transform: translateY(0);
                transform: translateY(0);
        margin-left: 0;
    }
}
@keyframes animate {
    0% {
        color: #f8de01;
        -webkit-transform: translateY(0);
                transform: translateY(0);
        margin-left: 0;
    }
    25% {
        color: #f8de01;
        -webkit-transform: translateY(-15px);
                transform: translateY(-15px);
        margin-left: 10px;
    }
    100% {
        color: #ff6e00;
        -webkit-transform: translateY(0);
                transform: translateY(0);
        margin-left: 0;
    }
}
.MuiDialog-paperScrollPaper {
    padding: 80px 30px 30px 30px;
    max-height: calc(100% - 0px) !important;
    box-shadow: none !important;
    border-radius: 15px !important;
}

.MuiDialogContent-dividers {
    border: none !important;
    padding: 0px !important;
    padding-top: 0 !important;
}

.MuiDialogContentText-root {
    border: 2px solid #3A92A0;
    padding: 20px;
    text-align: center;
    margin-bottom: 0 !important;
}

.MuiDialogContentText-root .img-holder {
    height: 480px;
    width: 480px;

}

.MuiDialogContentText-root .bg-stretch {
    height: 100%;
    width: 100%;
    border-radius: 15px;
}

.MuiDialogContentText-root .MuiTypography-h6 p {
    font-size: 16px;
    line-height: 2;
    margin: 0;
    margin-top: 20px;
    padding: 0 !important;
}

.MuiDialogActions-root .MuiSvgIcon-root {
    fill: #3A92A0;
    font-size: 32px;
}

.MuiDialogActions-root {
    position: absolute !important;
    right: 0;
    top: 8px;
}

.MuiDialogActions-root .MuiSvgIcon-root {
    fill: #3A92A0;
    font-size: 32px;
}

@media (max-width: 600px) {
    .MuiDialogContentText-root .img-holder {
        height: 150px;
        width: 190px;

    }
}

@media (min-width: 992px) {
    .MuiDialogContentText-root .img-holder {
        height: 480px;
        width: 480px;
    }
}

@media (min-width: 1400px) {
    .MuiDialogContentText-root .img-holder {
        height: 900px;
        width: 860px;
    }
    .MuiDialog-paperScrollPaper{
        max-width: 2000px !important;
    }
}

@media (min-width: 1600px) {
    .MuiDialogContentText-root .img-holder {
        height: 900px;
        width: 860px;
    }
    .MuiDialog-paperScrollPaper{
        max-width: 2000px !important;
    }
}

@media (max-width: 1900px) and (min-width: 2550px) {
    .MuiDialog-paperScrollPaper{
        max-width: 2000px !important;
    }
    .MuiDialogContentText-root .img-holder {
        height: 1200px;
        width: 1890px;
    }
}

.breadcrumb-wrapper{
    background-color: #e9ecef;
    padding: 0;
}

.breadcrumb{
    padding-left: 0;
    padding-right: 0;
}

.contact-sec .formbox .inputbox input[type="submit"]{
    background: #ffffff;
    padding: 8px 20px;
    margin-top: -10px;
    width: 100px;
    cursor: pointer;
    background: #ff5064;
    color: #000;
    font-size: 18px;
    font-weight: 400;
    border: none;
    text-transform: uppercase;
    letter-spacing: 4px;
    z-index: 2;
  }
  
  .contact-sec .formbox .inputbox input[type="submit"]:focus{
    background: #002147;
    color: #111111;
  }
  
  .contact-sec .formbox form .inputbox input:focus ~ span,
  .contact-sec .formbox form .inputbox textarea:focus ~ span{
    -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
    color: #002147 !important;
    font-size: 14px;
  }
  
  .contact-sec .formbox form .inputbox input:focus ,
  .contact-sec .formbox form .inputbox textarea:focus ,
  .contact-sec .formbox form .inputbox input:valid ,
  .contact-sec .formbox form .inputbox textarea:valid {
    border-bottom-color: #002147 !important;
    z-index: 2;
  }
  
  @media (max-width: 991px){
    .contact-sec .formbox{
        width: 100%;
        padding: 50px 50px;
    }
  
    .contact-sec .formbox form{
        position: relative;
        width: 100%;
        max-width: 500px;
        padding: 0;
    }
  
    .contact-sec .imgBox{
        display: none;
    }
  }
  
.demo {
  padding: 30px;
  max-width: 1400px !important;
}
.demo h1 {
  font-weight: 900;
  font-size: 42px;
  color: #0088fd;
}

.demo span {
  font-size: 30px;
  font-weight: 600;
  color: #73738c;
}

.demo .content-holder {
  margin-top: 20px;
}

.demo p {
  font-size: 8px;
  font-weight: 600;
  color: #0099fd;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.demo .bg-stretch2 {
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 200px;
  background-position: 50% 50%;
  position: relative;
  box-shadow: 10px 10px 30px #ccc;
}

.demo .bg-stretch2:hover .overlay {
  opacity: 1;
}

.demo .bg-stretch2:hover {
  box-shadow: none;
}

.demo .bg-stretch2 .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 1s ease;
  background-color: rgba(0, 0, 0, 0.5);
}

.demo .login {
  text-align: right;
}

.demo .bg-stretch2 .overlay .text-holder {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.demo .bg-stretch2 .overlay .text-holder h3 {
  font-size: 28px;
  font-weight: 700;
  text-transform: capitalize;
}

.demo .login .btn-primary {
  background: #007bff;
  border: 1px solid #007bff;
  border-radius: 30px;
  padding: 3px 8px;
  font-weight: 500;
  font-size: 12px;
}

.demo .login .btn-primary:hover {
  background: #ffffff;
  border: 1px solid #fca000;
  color: #434343;
  transition: 1s linear color;
}

