.styles_scroll-to-top__2A70v {
  border-radius: 50% !important;
  background: linear-gradient(
    45deg,
    #7d5ff9 0%,
    #675fef 43%,
    #2876bd 92%
  ) !important;
  outline: none !important;
}

.styles_scroll-to-top__2A70v svg {
  fill: #fff !important;
  height: 22px;
}

.ReactModal__Overlay {
  z-index: 10000 !important;
}

.menu-active .millsberryClz-contact-menu{
  z-index: 8 !important;
}
