.MuiDialog-paperScrollPaper {
    padding: 80px 30px 30px 30px;
    max-height: calc(100% - 0px) !important;
    box-shadow: none !important;
    border-radius: 15px !important;
}

.MuiDialogContent-dividers {
    border: none !important;
    padding: 0px !important;
    padding-top: 0 !important;
}

.MuiDialogContentText-root {
    border: 2px solid #3A92A0;
    padding: 20px;
    text-align: center;
    margin-bottom: 0 !important;
}

.MuiDialogContentText-root .img-holder {
    height: 480px;
    width: 480px;

}

.MuiDialogContentText-root .bg-stretch {
    height: 100%;
    width: 100%;
    border-radius: 15px;
}

.MuiDialogContentText-root .MuiTypography-h6 p {
    font-size: 16px;
    line-height: 2;
    margin: 0;
    margin-top: 20px;
    padding: 0 !important;
}

.MuiDialogActions-root .MuiSvgIcon-root {
    fill: #3A92A0;
    font-size: 32px;
}

.MuiDialogActions-root {
    position: absolute !important;
    right: 0;
    top: 8px;
}

.MuiDialogActions-root .MuiSvgIcon-root {
    fill: #3A92A0;
    font-size: 32px;
}

@media (max-width: 600px) {
    .MuiDialogContentText-root .img-holder {
        height: 150px;
        width: 190px;

    }
}

@media (min-width: 992px) {
    .MuiDialogContentText-root .img-holder {
        height: 480px;
        width: 480px;
    }
}

@media (min-width: 1400px) {
    .MuiDialogContentText-root .img-holder {
        height: 900px;
        width: 860px;
    }
    .MuiDialog-paperScrollPaper{
        max-width: 2000px !important;
    }
}

@media (min-width: 1600px) {
    .MuiDialogContentText-root .img-holder {
        height: 900px;
        width: 860px;
    }
    .MuiDialog-paperScrollPaper{
        max-width: 2000px !important;
    }
}

@media (max-width: 1900px) and (min-width: 2550px) {
    .MuiDialog-paperScrollPaper{
        max-width: 2000px !important;
    }
    .MuiDialogContentText-root .img-holder {
        height: 1200px;
        width: 1890px;
    }
}